import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Image, Pressable } from 'react-native';
import { useFonts } from 'expo-font';

import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import { Jogar } from './Jogar';
import { Manual } from './Manual';
import { GameContextProvider } from './GameContext';
import { Match } from './Match';

export function Home(props) {

  return (
    <View style={styles.container}>
      <Image style={styles.acaraje_logo} source={require('./assets/acaraje-logo.png')} />
      <Pressable style={styles.button} onPress={() => props.navigation.navigate('Jogar')}>
        <Text style={styles.text}>Jogar</Text>
      </Pressable>
  {/**
      <Pressable style={styles.button} onPress={() => props.navigation.navigate('Manual')}>
        <Text style={styles.text}>Manual</Text>
      </Pressable>
      **/}
      <StatusBar style="auto" />
    </View>
  );
}
export default function App() {
  const [loaded] = useFonts({
    Poppins: require('./assets/fonts/Poppins-Regular.ttf'),
  });

  if (!loaded) {
    return null;
  } 

  //const
  const Stack = createStackNavigator();

  return (
    <GameContextProvider>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            headerShown: false
          }}
        >
          <Stack.Screen name="Home" component={Home} />
          <Stack.Screen name="Jogar" component={Jogar} />
          <Stack.Screen name="Manual" component={Manual} />
          <Stack.Screen name="Match" component={Match} />
        </Stack.Navigator>
      </NavigationContainer>
    </GameContextProvider>
  );
};

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#E0ECDC',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 100
  },
  acaraje_logo: {
    width: 200,
    height: 200,
    marginBottom: 30,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    //paddingVertical: 26,
    width: 380,
    height: 100,
    marginVertical: 5,
    //paddingHorizontal: 150,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: '#F37106',
  },
  text: {
    fontFamily: 'Poppins',
    fontSize: 40,
    fontWeight: 'bold',
    color: "#FFF"
  },
  backbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    height: 70,
    marginVertical: 5,
    borderRadius: 4,
    elevation: 1,
    backgroundColor: '#F37106',
  }
});
