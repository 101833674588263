import React, { useContext, useEffect, useMemo } from 'react';
import { Text, View, Image, Pressable, Modal } from 'react-native';
import { GameContext } from "./GameContext";
import { data } from './data';
import { CountdownCircleTimer, useCountdown } from 'react-native-countdown-circle-timer'
import { ScrollView } from 'react-native';

export function Match(props) {
  const {
    time,
    startGame,
    queue,
    day,
    setDone,
    running,
    setRunning,
    setTime,
    setDay,
    setQueue,
    dayIsCompleted,
    players,
    dayDuration,
    finishDay,
  } = useContext(GameContext);

  useEffect(startGame, []);
  const playPauseImage = useMemo(() => {
    return running ? require("./assets/TablerPlayerPauseFilled.png") : require("./assets/TablerPlayerPlayFilled.png");
  }, [running]);

  return (
    <View style={{
      flex: 1,
      backgroundColor: "gray"
    }}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={!running}
        onRequestClose={() => {
          setRunning(running);
        }}>
        <View style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 22,
        }}>
          <View style={{
            margin: 20,
            backgroundColor: 'white',
            borderRadius: 20,
            padding: 35,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
              width: 5,
              height: 5,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
          }}>
            <Text style={{
              marginBottom: 15,
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: 30,
              fontWeight: 'bold'
            }}>{dayIsCompleted ? "Dia finalizado!" : "Jogo pausado!"}</Text>
            <Pressable style={{
              alignItems: 'center',
              justifyContent: 'center',
              //paddingVertical: 26,
              width: 200,
              height: 75,
              marginVertical: 5,
              //paddingHorizontal: 150
              borderRadius: 4,
              elevation: 3,
              backgroundColor: '#F37106',
            }} onPress={() => {
              setRunning(false);
              setTime(0);
              setDay(0);
              setQueue([]);
              props.navigation.navigate('Home');
            }}>
              <Text style={{
                fontFamily: 'Poppins',
                fontSize: 30,
                fontWeight: 'bold',
                color: "#fff"
              }}>Tela inicial</Text>
            </Pressable>
            <Pressable style={{
              alignItems: 'center',
              justifyContent: 'center',
              //paddingVertical: 26,
              width: 200,
              height: 75,
              marginVertical: 5,
              //paddingHorizontal: 150
              borderRadius: 4,
              elevation: 3,
              backgroundColor: '#F37106',
            }} onPress={() => {
              if (dayIsCompleted) {
                if (day + 1 === 5) {
                  setRunning(false);
                  setTime(0);
                  setDay(0);
                  setQueue([]);
                  props.navigation.navigate('Home');
                } else {
                  setDay(d => d + 1);
                  setQueue([]);
                  startGame();
                  setRunning(true);
                }
              } else {
                setRunning(true);
              }
            }}>
              <Text style={{
                fontFamily: 'Poppins',
                fontSize: 30,
                fontWeight: 'bold',
                color: "#fff"
              }}>Continuar</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
      <View style={{
        flex: 1,
        backgroundColor: '#E0ECDC',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 100,
        opacity: running ? 1 : 0.25,
      }}>
        <Text style={{
          fontFamily: 'Poppins',
          fontSize: 50,
          fontWeight: 'bold',
          position: "absolute",
          top: 30,
          left: 30,
        }}>
          {["Segunda", "Terça", "Quarta", "Quinta", "Sexta"][day]}
        </Text>

        <View style={{
          position: "absolute",
          top: 25,
          right: 25,
        }}>
          <CountdownCircleTimer
            isPlaying={running}
            duration={dayDuration}
            colors={['#1D6C28', '#F7B801', '#A30000', '#000']}
            colorsTime={[dayDuration, dayDuration / 2, dayDuration / 4, 0]}
            size={80}
            strokeWidth={7}
            key={day}
            trailColor='#ffffff00'
            onComplete={finishDay}
          >
            {({ remainingTime }) => <Text style={{
              fontFamily: 'Poppins',
              fontSize: 20,
              fontWeight: 'bold'
            }}>
              {new Date(remainingTime * 1000).getUTCMinutes().toString().padStart(2, "0")}:{new Date(remainingTime * 1000).getSeconds().toString().padStart(2, "0")}
            </Text>}
          </CountdownCircleTimer>
        </View>

        <ScrollView style={{
          width: "100%",
          height: "80vh",
          marginTop: 30,
          marginBottom: 30,
        }}>
          {queue.map((plate, index) => (
            <Pressable key={index} onPress={() => setDone(plate.id)}>
              <View style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#F37106",
                borderRadius: 5,
                // paddingHorizontal: 10,
                marginVertical: 10,
                width: "100%",
                height: "auto",
              }}>
                <Image style={{
                  width: 327,
                  height: 459,
                  resizeMode: 'contain',
                  opacity: plate.done ? 0.5 : 1,
                  // paddingHorizontal: 90,
                  // paddingVertical: 10
                }} source={plate.image} />
                {/* <Text>
                {plate.name}
              </Text>
              <Text>
                {plate.level}
              </Text> */}
              </View>
            </Pressable>
          ))}
        </ScrollView>
        <Text style={{
          fontFamily: 'Poppins',
          fontSize: 40,
          fontWeight: 'bold',
          position: "absolute",
          bottom: 30,
          left: 30,
        }}>
          {queue.filter(p => p.done).length}/{data.players[players].platesByWeekdays[day]}
        </Text>
        <Pressable style={{
          position: "absolute",
          bottom: 30,
          right: 30
        }} onPress={() => {
          setRunning(prev => !prev);
        }}>
          <Image style={{
            width: 80,
            height: 80,
          }} source={(playPauseImage)} />
        </Pressable>
      </View>
    </View>
  );
}
