export const data = {
    players: {
        2: {
            platesByWeekdays: [2, 3, 4, 5, 7],
            platesBySeconds: 50,
            dayDuration: 14,
        },
        3: {
            platesByWeekdays: [3, 4, 5, 6, 8],
            platesBySeconds: 53,
            dayDuration: 15,
        },
        4: {
            platesByWeekdays: [4, 5, 6, 7, 9],
            platesBySeconds: 56,
            dayDuration: 18,
        },
        5: {
            platesByWeekdays: [5, 6, 7, 8, 10],
            platesBySeconds: 59,
            dayDuration: 20,
        },
        6: {
            platesByWeekdays: [6, 7, 8, 9, 11],
            platesBySeconds: 59,
            dayDuration: 25,
        },
    },
    probabilitiesByDay: {
        common: [80, 75, 70, 50, 50],
        epic: [15, 20, 20, 35, 30],
        legendary: [5, 5, 10, 15, 20],
    },
    platesByCountry: {
        brazil: [
            {
                name: "Prato feito",
                level: "legendary",
                image: require("./assets/prato-feito.png")
            },
            {
                name: "Feijoada",
                level: "epic",
                image: require("./assets/feijoada.png")
            },
            {
                name: "Acarajé",
                level: "common",
                image: require("./assets/acaraje.png")
            },
            {
                name: "Coxinha de frango",
                level: "common",
                image: require("./assets/coxinha-de-frango.png")
            },
        ],
        italia: [
            {
                name: "Macarrão a bolonhesa",
                level: "legendary",
                image: require("./assets/macarrao-a-bolonhesa.png")
            },
            {
                name: "Pizza de marguerita",
                level: "epic",
                image: require("./assets/pizza-de-marguerita.png")
            },
            {
                name: "Polpettone",
                level: "common",
                image: require("./assets/polpettone.png")
            },
            {
                name: "Risotto com carne",
                level: "common",
                image: require("./assets/risotto-com-carne.png")
            },
        ],
        japan: [
            {
                name: "Lamén",
                level: "legendary",
                image: require("./assets/lamen.png")
            },
            {
                name: "Guioza",
                level: "epic",
                image: require("./assets/guioza.png")
            },
            {
                name: "Temaki",
                level: "common",
                image: require("./assets/temaki.png")
            },
            {
                name: "Karaage",
                level: "common",
                image: require("./assets/karaage.png")
            },
        ],
        arabia: [
            {
                name: "Schwarma",
                level: "legendary",
                image: require("./assets/schwarma.png")
            },
            {
                name: "Esfiha de carne",
                level: "epic",
                image: require("./assets/esfiha-de-carne.png")
            },
            {
                name: "Yabrak",
                level: "common",
                image: require("./assets/yabrak.png")
            },
            {
                name: "Kafta recheada",
                level: "common",
                image: require("./assets/kafta-recheada.png")
            },
        ],
    },
};
