import { StatusBar } from 'expo-status-bar';
import React, { useContext, useMemo } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { GameContext } from "./GameContext";

export function Jogar(props) {
  const {
    players,
    setPlayers,
    nationality,
    setNationality,
  } = useContext(GameContext);

  // Aumenta o valor de players apenas se for menos que 6.
  const aumentar = () => {
    if (players < 6) {
      setPlayers(players + 1);
    }
  };

  // Diminui o valor de players apenas se for maior que 2.
  const diminuir = () => {
    if (players > 2) {
      setPlayers(players - 1);
    }
  };

  // Imagem depende da quantidade de players
  // Toda vez que o estado de "players" mudar,
  // essa função roda e redefine o "imageSource".
  const imageSource = useMemo(() => {
    switch (players) {
      case 2:
        return require('./assets/duas-pessoas.png');

      case 3:
        return require('./assets/tres-pessoas.png');

      case 4:
        return require('./assets/quatro-pessoas.png')

      case 5:
        return require('./assets/cinco-pessoas.png')

      case 6:
        return require('./assets/seis-pessoas.png')

      default:
        return require('./assets/duas-pessoas.png');
    }
  }, [players]);

  const imageNatSource = useMemo(() => {
    switch (nationality) {

      case 1:
        return require('./assets/brasil-flag.jpg');

      case 2:
        return require('./assets/japan-flag.jpg');

      case 3:
        return require('./assets/italia-flag.jpg');

      case 4:
        return require('./assets/arab-flag.jpg');
    }
  }, [nationality]);
  return (
    <View style={appstyles.container}>
      <Text style={appstyles.text}></Text>
      <View style={styles.card}>
        <Image style={styles.peopleImage} source={imageSource} />
        <View style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: '#FBAF98'
        }}>
          <Pressable style={styles.arrowPressable} onPress={aumentar}>
            <Image style={styles.arrowImage} source={require('./assets/seta-cima.png')} />
          </Pressable>
          <Text style={styles.numberText}>{players}</Text>
          <Pressable style={styles.arrowPressable} onPress={diminuir}>
            <Image style={styles.arrowImage} source={require('./assets/seta-baixo.png')} />
          </Pressable>
        </View>
      </View>
      <View style={styles.card}>
        <View style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          color: '#FBAF98'
        }}>
          <Pressable style={styles.arrowPressable} onPress={() => {
            if (nationality > 1) {
              setNationality(nationality - 1);
            }
          }}>
            <Image style={styles.arrowImage} source={require('./assets/seta-esquerda.png')} />
          </Pressable>
          <Image style={styles.flagImage} source={imageNatSource} />
          {/* <Text style={styles.numberText}>{nationality}</Text> */}
          <Pressable style={styles.arrowPressable} onPress={() => {
            if (nationality < 4) {
              setNationality(nationality + 1);
            }
          }}>
            <Image style={styles.arrowImage} source={require('./assets/seta-direita.png')} />
          </Pressable>
        </View>
      </View>
      <Pressable style={styles.backbutton} onPress={() => {
        props.navigation.navigate('Home');
      }}>
        <Text style={{
          ...appstyles.text,
          fontSize: 30,
        }}>Voltar</Text>
      </Pressable>
      <Pressable style={styles.playButton} onPress={() => {
        props.navigation.navigate('Match');
      }}>
        <Text style={{
          ...appstyles.text,
          fontSize: 30,
        }}>Jogar</Text>
      </Pressable>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F37106',
    alignItems: 'end',
    justifyContent: 'end',
    paddingTop: 100
  },
  backbutton: {
    position: "absolute",
    bottom: 30,
    left: 30,
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    height: 70,
    marginVertical: 5,
    borderRadius: 20,
    elevation: 1,
    backgroundColor: '#F37106',
  },
  playButton: {
    position: "absolute",
    bottom: 30,
    right: 30,
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    height: 70,
    marginVertical: 5,
    borderRadius: 20,
    elevation: 1,
    backgroundColor: '#F37106',
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F37106",
    borderRadius: 5,
    paddingHorizontal: 10,
    marginVertical: 10,
    width: 300,
    height: 150,
  },
  peopleImage: {
    width: 150,
    height: 150,
    paddingHorizontal: 90,
    paddingVertical: 10
  },
  flagImage: {
    width: 100,
    height: 100,
    paddingHorizontal: 90,
    paddingVertical: 10
  },
  arrowImage: {
    width: 50,
    height: 50
  },
  numberText: {
    fontFamily: 'Poppins',
    fontSize: 30,
    fontWeight: 'bold',
    color: "#ffffff",
  },
  arrowPressable: {

  },
});


export const appstyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#E0ECDC',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 100
  },
  acaraje_logo: {
    width: 230,
    height: 200,
    marginBottom: 30,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    //paddingVertical: 26,
    width: 380,
    height: 100,
    marginVertical: 5,
    //paddingHorizontal: 150,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: '#F37106',
  },
  text: {
    fontFamily: 'Poppins',
    fontSize: 40,
    fontWeight: 'bold',
    color: "#FFF"
  },
  backbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    height: 70,
    marginVertical: 5,
    borderRadius: 4,
    elevation: 1,
    backgroundColor: '#F37106',
  }
});

