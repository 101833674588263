import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Pressable } from 'react-native';

export function Manual(props) {
  const onPress = () => {
    props.navigation.navigate('Home');
  };

  return (
    <View style={appstyles.container}>
        <Text style={appstyles.text}>manual</Text>
      <Pressable style={styles.backbutton} onPress={onPress}>
        <Text style={appstyles.text}>Voltar</Text>
      </Pressable>
      <StatusBar style="auto" />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#111024',
    alignItems: 'end',
    justifyContent: 'end',
    paddingTop: 100
  },
  backbutton: {
    position: "absolute",
    bottom: 30,
    left: 30,
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    height: 70,
    marginVertical: 5,
    borderRadius: 4,
    elevation: 1,
    backgroundColor: '#F37106',
  }
});

export const appstyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#E0ECDC',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 100
  },
  acaraje_logo: {
    width: 230,
    height: 200,
    marginBottom: 30,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    //paddingVertical: 26,
    width: 380,
    height: 100,
    marginVertical: 5,
    //paddingHorizontal: 150,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: '#F37106',
  },
  text: {
    fontFamily: 'Poppins',
    fontSize: 40,
    fontWeight: 'bold',
    color: "#FFF"
  },
  backbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    height: 70,
    marginVertical: 5,
    borderRadius: 4,
    elevation: 1,
    backgroundColor: '#F37106',
  }
});
