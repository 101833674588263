import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import { data } from "./data";

export function uuid(digits = 16) {
    let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
    let uuid = [];
    for (let i = 0; i < digits; i++) {
        uuid.push(str[Math.floor(Math.random() * str.length)]);
    }
    return uuid.join('');
}

function chooseRandom(list) {
    return list[Math.floor((Math.random() * list.length))];
}

export const GameContext = createContext({});

export function GameContextProvider({ children }) {
    // Um estado que se inicia em 3 e nos dá o valor atual "players"
    // e uma função para atualizar o valor
    const [players, setPlayers] = useState(3);
    const [nationality, setNationality] = useState(1);
    const [gamemode, setGamemode] = useState(1);

    const [running, setRunning] = useState(false);
    const [time, setTime] = useState(0);
    const [day, setDay] = useState(0);
    const [dayDuration, setDayDuration] = useState(0);
    const [queue, setQueue] = useState([]);

    const [timeoutIntervalId, setTimeoutIntervalId] = useState();
    const [addPlateIntervalId, setAddPlateIntervalId] = useState();

    const [modal, setModal] = useState(true);

    const dayIsCompleted = useMemo(() => queue.every(p => p.done) && queue.length === data.players[players].platesByWeekdays[day], [queue, day, players]);

    const setDone = useCallback((plateId) => {
        setQueue(prev => {
            return prev.map(p => p.id === plateId ? { ...p, done: !p.done } : p);
        });
    }, [queue]);

    const generateNewPlate = useCallback(() => {
        // dependendo da nacionalidade, eu defino a lista de pratos
        let plates =
            nationality === 1 ?
                data.platesByCountry["brazil"] :
                nationality === 2 ?
                    data.platesByCountry["japan"] :
                    nationality === 3 ?
                        data.platesByCountry["italia"] :
                        nationality === 4 ?
                            data.platesByCountry["arabia"] :
                            data.platesByCountry["brazil"];

        const probCommon = data.probabilitiesByDay.common[day];
        const probEpic = data.probabilitiesByDay.epic[day];
        const probLegendary = data.probabilitiesByDay.legendary[day];

        const probabilities = [];

        for (let i = 0; i < probCommon; i++) probabilities.push("common");
        for (let i = 0; i < probEpic; i++) probabilities.push("epic");
        for (let i = 0; i < probLegendary; i++) probabilities.push("legendary");

        const level = chooseRandom(probabilities);
        const newPlate = {
            ...chooseRandom(plates.filter(p => p.level === level)),
            id: uuid(),
            done: false,
        };

        setQueue(prev => [...prev, newPlate]);
    }, [nationality, day, setQueue]);

    const startGame = useCallback(() => {
        // definindo o tempo inicial
        setTime(data.players[players].dayDuration * 60);
        setDayDuration(data.players[players].dayDuration * 60);
        setRunning(true);

        generateNewPlate();
    }, [players, nationality, queue]);

    useEffect(() => {
        if (queue.length === data.players[players].platesByWeekdays[day]) {
            clearInterval(addPlateIntervalId);
        }
    }, [queue, addPlateIntervalId, day, players]);

    useEffect(() => {
        if (dayIsCompleted) {
            setRunning(false);
            clearInterval(timeoutIntervalId);
            clearInterval(addPlateIntervalId);
        }
    }, [dayIsCompleted, setRunning]);

    const finishDay = useCallback(() => {
        setRunning(false);
        clearInterval(timeoutIntervalId);
    }, [timeoutIntervalId, time]);

    useEffect(() => {
        if (time === 0) {
            finishDay();
        }
    }, [finishDay, time]);

    useEffect(() => {
        if (running) {
            // a cada 1 segundo, abaixar esse tempo
            const timeoutIntervalId = setInterval(() => {
                setTime(prev => prev - 1);
            }, 1000);
            setTimeoutIntervalId(timeoutIntervalId);

            // a cada tempo definido para aparecer um prato,
            // adicionar um novo prato a fila
            const intervalId = setInterval(() => generateNewPlate(intervalId), data.players[players].platesBySeconds * 1000);
            setAddPlateIntervalId(intervalId);
        } else {
            clearInterval(timeoutIntervalId);
            clearInterval(addPlateIntervalId);
        }
    }, [running]);

    return (
        <GameContext.Provider
            value={{
                setDone,
                startGame,
                finishDay,
                dayIsCompleted,
                running, setRunning,
                players, setPlayers,
                nationality, setNationality,
                gamemode, setGamemode,
                time, setTime,
                day, setDay,
                queue, setQueue,
                modal, setModal,
                dayDuration, setDayDuration,
            }}
        >
            {children}
        </GameContext.Provider>
    );
}
